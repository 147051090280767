import './polyfills/classlist.js';

import './polyfills/object.assign';
import './polyfills/array.from';
import './polyfills/array.foreach';
import './polyfills/array.map';
import './polyfills/array.includes';
import './polyfills/array.find';

import './polyfills/string.includes';

import es6Promise from './polyfills/promises';
es6Promise.polyfill();

import './polyfills/fetch';
